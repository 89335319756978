.property_card {
  position: relative;
  width: 100%;
  height: 500px;
  background-size: cover;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.35, 0.755, 0.42, 0.95);
  overflow: hidden;
  cursor: pointer;
}

.property_card::after {
  content: "";
  background: linear-gradient(180deg, rgba(4, 4, 8, 0) 0%, #040408 111.37%);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0 0 0 48px;
  z-index: 2;
}

.property_card,
.property_img {
  border-radius: 0px 48px;
}

.property_img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
  user-select: none;
}

.property_card:hover .property_img {
  transform: scale(1);
}

.text_section {
  position: relative;
  z-index: 3;
  bottom: -400px;
  left: 2.125rem;
  color: #ffffff;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}

.property_card:hover .text_section{
  bottom: -380px;
}

.text_section .title {
  font-family: "Noto Serif Display";
  font-size: 1.75rem;
}

.text_section .details {
  font-size: 1rem;
}

@media screen and (max-width: 991.9px) {
  .property_card {
    height: 300px;
  }

  .text_section {
    bottom: -215px;
  }

  .property_card:hover .text_section{
    bottom: -205px;
  }

  .text_section .title {
    font-size: 0.875rem;
  }

  .text_section .details {
    font-size: 0.75rem;
  }


}
