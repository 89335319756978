.header_section {
  width: 100%;
  height: 780px;
  position: relative;
}

.header_section #website_video {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header_section::after {
  content: "";
  background: rgba(6, 6, 11, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
}

.header_section .text_section {
  position: relative;
  z-index: 2;
  color: #ffffff;
  width: 60%;
  margin: 15rem auto;
  text-align: center;
}

.header_section .text_section .title {
  font-size: 3.5rem;
  font-weight: 500;
  font-family: "Noto Serif Display";
  margin-bottom: 0.5rem;
}

.header_section .text_section .details {
  font-size: 1.125rem;
  font-weight: 300;
  margin: 0 6rem;
}

.about_section,
.vision_section {
  padding: 5.125rem 0;
}

.about_section .text_section,
.vision_section .text_section {
  position: relative;
  z-index: 1;
}

.about_section .text_section .title,
.vision_section .text_section .title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #58585c;
  display: block;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.about_section .text_section .title::after,
.vision_section .text_section .title::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #b2b2b3;
}

.about_section .text_section .details,
.vision_section .text_section .details {
  font-size: 1.125rem;
  font-weight: 300;
  color: #06060b;
  margin-bottom: 4rem;
}

.vision_section .text_section {
  width: 65%;
}

.core_value_section {
  margin: 7rem 0 4rem;
  position: relative;
}

.core_value_section .core_value_title{
  left: -3rem;
  top: -8rem;
}

.value_card {
  position: relative;
  z-index: 1;
  width: 90%;
  margin-bottom: 4.125rem;
}

.value_card .title {
  color: #58585c;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  position: relative;
}

.value_card .title::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #b2b2b3;
}

.value_card .details {
  color: #06060b;
  font-size: 1.125rem;
  font-weight: 300;
}

.team_section {
  background: url("../../assets/images/bg/slasph-overlay.png"), #06060b;
  mix-blend-mode: normal;
  padding: 6rem 0 10rem;
}

.team_section .text_section {
  color: #ffffff;
}

.team_section .text_section .title {
  font-size: 3rem;
  font-weight: 500;
  font-family: "Noto Serif Display";
  margin-bottom: 1rem;
}

.team_section .text_section .details {
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 3.5rem;
  width: 35%;
}

@media screen and (max-width: 991.9px) {
  .header_section {
    height: 570px;
  }

  .header_section .text_section {
    width: 100%;
    margin: 10rem auto;
  }

  .header_section .text_section .title {
    font-size: 1.5rem;
  }

  .header_section .text_section .details {
    font-size: 0.875rem;
    margin: 0 0.5rem;
  }

  .about_section,
  .vision_section {
    padding: 4rem 0;
  }

  .about_section .text_section .title,
  .vision_section .text_section .title {
    font-size: 0.875rem;
    margin-bottom: 0.8rem;
  }

  .about_section .text_section .details,
  .vision_section .text_section .details {
    font-size: 0.875rem;
    margin-bottom: 2.5rem;
  }

  .vision_section .text_section {
    width: 100%;
  }

  .core_value_section {
    margin: 3.5rem 0 2rem;
    position: relative;
  }

  .core_value_section .core_value_title{
    left: 0;
    top: -2rem;
  }


  .value_card {
    width: 100%;
    margin-bottom: 1.25rem;
  }

  .value_card .title {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
  }

  .value_card .details {
    font-size: 0.875rem;
  }

  .team_section {
    padding: 2.5rem 0 3rem;
  }

  .team_section .text_section .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .team_section .text_section .details {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }
}
