.footer_container {
  background-color: #06060b;
  background-image: url("../../../assets/images/bg/slasph-overlay.png");
  padding-top: 5.5rem;
}

.footer_logo_links {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.footer_links {
  display: flex;
  gap: 0 4rem;
  flex-direction: row;
  justify-content: center;
  margin: 3.5rem auto 6.5rem;
  list-style: none;
  padding: 0;
}

.footer_links li a {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
}


.contact_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 1.35rem;
  border-bottom: 1px solid #38383c;
  color: #ffffff;
}

.contact_section .address{
  width: 30%;
  margin-bottom: 0;
}

.footer_note {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 3.5rem;
  font-size: 0.875rem;
}

.contact_links {
  display: flex;
  gap: 0 6rem;
}

.contact_links a {
  /* border-bottom: 1px solid #ffffff; */
  font-weight: 500;
  position: relative;
}

.contact_links a::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  display: block;
  position: absolute;
  left: 0;
  
}

@media screen and (max-width: 991px) {
  .footer_logo_links img {
    width: 85px;
  }

  .footer_links {
    gap: 1.75rem 0;
    flex-direction: column;
    text-align: center;
  }

  .contact_section {
    justify-content: center;
    padding: 0.5rem 0 1.35rem;
    flex-direction: column;
    text-align: center;
  }

  .contact_section .address{
    width: 90%;
    margin-bottom: 2rem;
  }

  .footer_note {
    /* display: flex;
    align-items: center; */
    justify-content: center;
    flex-direction: column;
    padding: 1.25rem 1rem;
  }

  .contact_links {
    order: 1;
    flex-direction: column;
    gap: 1.25rem 0;
    align-items: center;
  }

  .contact_links a {
    text-align: center;
    display: inline;

  }

  .copyright {
    order: 2;
    margin-top: 1.5rem;
  }
}
