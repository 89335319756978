.hero_section {
  background: url("../../assets/images/bg/home-hero-section.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 780px;
  position: relative;
}

.hero_section::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 11, 0.5);
}

.hero_text_section {
  position: relative;
  z-index: 1;
  display: flex;
  height: 100%;
}

.text_container {
  color: #fff;
  margin-top: 10rem;
}

.text_container .title {
  font-family: "Noto Serif Display";
  font-size: 3.5rem;
  font-weight: 500;
  width: 80%;
  line-height: 1.65;
  margin-bottom: 0.5rem;
}

.text_container .details {
  font-size: 1rem;
  font-weight: 300;
  width: 50%;
  margin-bottom: 0em;
  line-height: 1.5;
}

.view_home_circle_container {
  position: absolute;
  display: inline-block;
  bottom: 200px;
  right: 0;
}

.view_home_circle_container .img_container {
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  background-color: #eeb04e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  z-index: 1;
  transition: all 0.8s ease-in-out;
}

.view_home_circle_container .img_container:hover {
  transform: scale(0.9);
}

.view_home_circle_container svg {
  animation: spinViewHomes 3s linear 7s infinite alternate;
  fill: #ffffff;
  height: auto;
  max-width: 80vmin;
  transform-origin: center;
  width: 140px;
}

.view_home_circle_container svg text {
  font-family: "Figtree";
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 700;
}

.journey_section {
  background-color: #fdf5e9;
  padding: 3.5rem 0;
  position: relative;
}

.journey_section::after {
  content: "";
  background-image: url("../../assets/images/bg/anglee-mask-bg.png");
  background-position: right;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.journey_section .text_section {
  margin-right: 3rem;
  position: relative;
  z-index: 1;
}

.journey_section .text_section .title {
  color: #38383c;
  margin: 0 3.5rem 2rem 0;
}

.journey_section .text_section .details {
  color: #06060b;
  margin-bottom: 3rem;
}

.why_section {
  background-color: #ffffff;
  padding: 3.5rem 0;
  position: relative;
}

.why_section .header_section {
  width: 50%;
  margin: 0 auto 4.35rem;
  text-align: center;
}

.why_section .header_section .title {
  color: #06060b;
}

.why_section .header_section .details {
  color: #06060b;
}

.slider_section {
  background-color: #06060b;
  background-image: url("../../assets/images/bg/slasph-overlay.png");
  padding: 6rem 0;

  overflow: hidden;
  position: relative;
}

.wwd_slider_container {
  position: relative;
}

.wwd_slider_container .wwd_slider_text {
  display: flex;
  gap: 0 2rem;
  color: #ffffff;
}

.wwd_slider_text .number {
  display: block;
  font-size: 1.5rem;
  /* margin: 0.5rem 1rem 0 0; */
}

.wwd_slider_text .header_section {
  color: #ffffff;
  position: relative;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  min-height: 470px;
}

.wwd_slider_container .wwd_slider_text .title {
  font-size: 1.125rem;
  font-weight: 500;
  position: relative;
  margin-bottom: 2rem;
}

.wwd_slider_container .wwd_slider_text .title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.5rem;
  height: 1px;
  background-color: #933a1f;
}

.wwd_slider_text .header_section {
  margin-right: 3rem;
}

.wwd_slider_text .header_section .main_title {
  font-family: "Noto Serif Display";
  font-size: 3rem;
  font-weight: 500;
  margin: 0 4rem 0rem 0;
}

.wwd_slider_text .header_section .details {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 1.5rem 0 3.5rem;
  line-height: 1.65;
}

.img_background {
  position: absolute;
  top: -6rem;
  bottom: -6rem;
  /* right: -6rem; */
  width: 100%;
  /* height: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  z-index: 2;
}

.img_background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(1, 1, 1, 0.55);
  /* background: url("../../assets/images/bg/Identifier.png"), rgba(1, 1, 1, 0.75);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover; */
}

.testimony_section {
  background-color: #ffffff;
  padding: 3.5rem 0;
  position: relative;
}

.testimony_section .text_section {
  margin: 0 5rem 0 7rem;
}

.testimony_section .text_section .title {
  color: #06060b;
}

.testimony_section .text_section .details {
  color: #06060b;
  margin: 1.5rem 0 3rem;
}

.testimony_list_container {
  position: relative;
  height: 700px;
  overflow-y: hidden;
}

.testimony_list_container .testimony_card {
  animation: loopVertically 5s linear infinite;
}

.testimony_list_container::before,
.testimony_list_container::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  height: 100px;
  background: linear-gradient(
    181.38deg,
    #ffffff 24.94%,
    rgba(255, 255, 255, 0) 67.09%
  );
}

.testimony_list_container::before {
  top: 0;
}

.testimony_list_container::after {
  top: 100%;
}

.video_section {
  width: 100%;
  height: 870px;
  position: relative;
  display: flex;
  align-items: center;
}

.video_section #website_video {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video_section::after {
  content: "";
  background: rgba(6, 6, 11, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
}

.video_section .text_section {
  width: 80%;
  margin: 6rem auto;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.video_section .text_section .title {
  font-size: 3.5rem;
  font-weight: 500;
  font-family: "Noto Serif Display";
}

.video_section .text_section .details {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 1rem 6rem 3rem;
}

.contact_seection {
  position: relative;
  background: #ffffff;
  height: 450px;
}

.contact_seection::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  background: #fdf5e9;
}

.follow_section,
.enquires_section {
  position: relative;
  z-index: 1;
  text-align: center;
}

.follow_section .title,
.enquires_section .title {
  font-family: "Noto Serif Display";
  color: #06060b;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.follow_section .follow_container {
  display: flex;
  gap: 0 0.7rem;
}

.follow_section .icon_holder {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background-color: rgba(5, 5, 10, 0.1);
}

.follow_section .icon_holder svg {
  width: 20px;
}

.follow_section .icon_holder svg path {
  fill: #05050a;
}

@media screen and (max-width: 991.9px) {
  .hero_section {
    height: 570px;
  }

  .text_container {
    margin-top: 6rem;
  }

  .text_container .title {
    font-size: 1.5rem;
    width: 100%;
  }

  .text_container .details {
    font-size: 0.875rem;
    width: 100%;
  }

  .view_home_circle_container {
    bottom: 150px;
    left: -0.5rem;
    right: unset;
  }

  .journey_section::after {
    background-position: center;
  }

  .journey_section .text_section {
    margin: 0 0 3rem 0;
  }

  .journey_section .text_section .title {
    margin: 0 2rem 2rem 0;
  }

  .journey_section .text_section .details {
    margin-bottom: 2.5rem;
  }

  .why_section .header_section {
    width: 100%;
    margin: 0 auto 3rem;
    text-align: center;
  }

  .slider_section {
    /* background-color: #06060b;
    background-image: url("../../assets/images/bg/slasph-overlay.png"); */
    padding: 4rem 0;
  }

  .wwd_slider_container .wwd_slider_text {
    gap: 0 0.6rem;
    z-index: 2;
  }

  .wwd_slider_text .number {
    font-size: 0.75rem;
    margin: 3rem 0 0;
  }

  .wwd_slider_container .wwd_slider_text .title {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  .wwd_slider_text .header_section {
    margin-right: 0;
  }

  .wwd_slider_text .header_section .main_title {
    font-size: 1.25rem;
    margin: 0;
  }

  .wwd_slider_text .header_section .details {
    font-size: 0.875rem;
    margin: 0.75rem 0 1.5rem;
    line-height: 1.7;
  }

  .img_background {
    position: absolute;
    top: -4rem;
    bottom: -4rem;
    left: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
    z-index: 1;
  }

  .img_background::after {
    content: "";
    background-color: rgba(1, 1, 1, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  .testimony_section .text_section {
    margin: 0 0 2.5rem 0;
    text-align: center;
    width: 100%;
  }

  .testimony_section .text_section .details {
    margin: 1rem 0 2rem;
  }

  .testimony_list_container {
    height: 900px;
  }

  .video_section {
    height: 600px;
  }

  .video_section .text_section {
    width: 100%;
    margin: 6rem auto;
  }

  .video_section .text_section .title {
    font-size: 1.5rem;
  }

  .video_section .text_section .details {
    font-size: 0.875rem;
    margin: 0.5rem 0 2rem;
  }

  .contact_seection {
    height: 400px;
  }

  .contact_seection::after {
    width: 100%;
    height: 50%;
  }

  .follow_section .title,
  .enquires_section .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

/* Animation heere */
@keyframes spinViewHomes {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(150deg);
  }

  50% {
    transform: rotate(120deg);
  }

  75% {
    transform: rotate(210deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes loopVertically {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-300px);
  }
}
