.card_section {
  background: rgba(230, 230, 231, 0.3);
  border-radius: 0px 24px;
  padding: 1.5rem 1.5rem 2.5rem;
  display: flex;
  margin-bottom: 1rem;
}

.avatar_holder {
  min-width: 46px;
  min-height: 46px;
  margin-right: 1rem;
}

.title {
  font-size: 1.125rem;
  color: #38383c;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.details {
  font-size: 0.875rem;
  color: #38383c;
  font-weight: 300;
  margin-bottom: 2.5rem;
}

.property_address{
    font-family: "Noto Serif Display";
    font-size: 1rem;
    font-weight: 500;
    color: #933A1F;
    text-align: end;
}
