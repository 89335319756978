.splash_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-image: url("../../assets/images/bg/splash-image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  transition: all 3.5s cubic-bezier(0.35, 0.755, 0.42, 0.95);
  overflow: hidden;
  z-index: 50;
  opacity: 1;
  display: block;
}

.close_splash {
  visibility: hidden;
  opacity: 0 !important;
}

.close_splash .logo {
  opacity: 1;
}

.splash_overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 51;
  background-image: url("../../assets//images/bg/slasph-overlay.png");
  background-color: rgba(0, 0, 0, 0.65);
  animation: darkAnimate 5s linear;
  transition: all 0.3s ease-in;
  /* opacity: 0.2; */
}

.breathe_container {
  position: relative;
  z-index: 52;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo {
  transform: scale(1);
  animation: logoALive 0.7s linear 3.5s infinite alternate;
  transition: all 0.5s ease-in-out;
}

@keyframes logoALive {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes logoDisappear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes darkAnimate {
  0% {
    background-color: rgba(0, 0, 0, 1);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.95);
  }

  50% {
    background-color: rgba(0, 0, 0, 0.85);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.75);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.65);
  }
}
