.faq_section {
  padding: 9rem 0;
}

.faq_container {
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.accordion_header {
  background: transparent;
}

.question_container {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.question_container span {
  font-family: "Noto Serif Display";
  margin-right: 0.5rem;
}

.answer_container {
  color: #05050a;
  font-weight: 300;
  font-size: 1rem;
}

@media screen and (max-width: 991.9px) {
  .faq_section {
    padding: 4rem 0;
  }

  .faq_container {
    width: 100%;
  }

  .question_container {
    font-size: 0.875rem;
  }

  .question_container span {
    font-size: 1.25rem;
  }

  .answer_container {
    font-size: 0.875rem;
  }
}
