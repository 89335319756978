.card {
  margin-bottom: 2rem;
  width: 95%;
}

.title {
  position: relative;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 1rem 0 1.5rem;
  line-height: 1.6;
}

.title span {
  color: #ea9c22;
  font-size: 1rem;
  margin-left: 0.625rem;
  font-family: "Noto Serif Display";
  text-transform: capitalize;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #58585c;
}

.details {
  font-size: 1.125rem;
  font-weight: 300;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  /* height: 81px; */
  position: relative;

  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden; */
}

.details span{
  color: #184D79;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-color: rgba(1, 1, 1, 0.95);
  padding: 0.1rem 0 0.1rem 0.25rem; */
}

.details_animate {
  transition: all 0.3s ease-in-out;
}

.see_all_details {
  max-height: 100%;
}

@media screen and (max-width: 991.9px) {
  .card {
    width: 100%;
  }

  .title {
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
  }

  .title span {
    font-size: 0.75rem;
  }

  .details {
    font-size: 0.875rem;
  }
}
