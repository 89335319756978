.hero_section {
  background-color: #dec7a6;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.hero_text_section {
  position: relative;
  z-index: 1;
  display: flex;
  height: 100%;
}

.text_container {
  color: #fff;
  margin-top: 10rem;
}

.text_container2 {
  color: #fff;
  margin-top: 1rem;
}

.text_container .title {
  font-family: "Noto Serif Display";
  font-size: 3.5rem;
  font-weight: 600;
  color: #933a1f;
  width: 85%;
  line-height: 1.65;
  margin-bottom: 0.5rem;
}

.text_container2 .title2 {
  font-family: "Noto Serif Display";
  font-size: 2.8rem;
  font-weight: 600;
  color: #933a1f;
  width: 60%;
  line-height: 1.65;
  margin-bottom: 0.5rem;
}

.text_container2 .details2 {
  font-size: 25px;
  font-weight: 300;
  line-height: 2.14rem;
  color: #361800;
  max-width: 72%;
  margin: 1rem 0 3rem 0;
}

.text_container .title::after {
  content: "";
  display: block;
  position: absolute;
  width: 756px;
  height: 2px;
  background-color: #933a1f;
  left: calc(100% - 685px);
  bottom: 0;
  margin-top: 0px;
  z-index: 1;
}

.text_container2 .title2::after {
  content: "";
  display: block;
  position: absolute;
  width: 756px;
  height: 2px;
  background-color: #933a1f;
  left: calc(100% - 685px);
  bottom: 0;
  margin-top: 0px;
  z-index: 1;
}

.firstImage {
  position: absolute;
  top: 0; 
  left: 0;
  width: 50%; 
  height: 100%;
  z-index: 1;
}

.overlapPicture {
  width: 100%;
  height: 100%;
}

.image_container {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-block: 3rem;
}

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-items: center;
  align-items: center;
}

.aboutContainer {
  display: flex;
  justify-content: center; 
}

.content_wrapper {
  display: flex;
  width: 90%; 
}

.left_content {
  flex: 1;
  position: relative;
}

.left_content .titlea {
  font-size: 20px;
  color: #361800;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
  font-family: "Noto Serif Display";
  line-height: 1.65;
  width: 85%;
  text-transform: uppercase;
  z-index: 1;
}

.titlea::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #8D3C25;
}

.right_content {
  flex: 2;
  margin-top: 60px;
  margin-right: 60px;
  width: 80%;
}

.right_content .details {
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    line-height: 42.29px;

}

.imagesection {
  padding: 100px 0;
}

.aboutImageContainer {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: auto auto;
}

.secondImage {
  position: absolute;
  top: 50px; 
  left: 50px;
  width: 50%; 
  height: 50%;
  z-index: 2;
}

.firstImage {
  position: relative;
  top: 0; 
  left: 300px;
  width: auto;
  height: 50%;
  z-index: 1; 
}

.imgFluid1 {
  width: 80%;
  height: auto;
}

.imgFluid2 {
  width: 88%;
  height: auto;
}

.assetContainer {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.assetContainerMain {
  display: flex;
  align-items: center;
}

.fastImage1 {
  width: 80%;
  height: auto;
}

.fastImage2 {
  width: 90%;
  height: auto;
  margin-left: 2rem;
}

.asset_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 2rem;
  max-width: 120px;
}

.asset_name,
.asset_value {
  font-size: 2rem;
  font-weight: 600;
  line-height: 28px;
  color: #361800;
  margin: 0.8rem 0;
  text-align: left;
}

.asset_value {
  font-size: 20px;
  font-weight: 300;
}

.why_section {
  padding-top: 3.5rem;
  padding-bottom: 15rem;
}

.why_section .header_section {
  width: 50%;
  margin: 0 auto 4.35rem;
  text-align: center;
}

.why_section .header_section .title {
  color: #8D3C25;
}

.why_section .header_section .details {
  color: #06060b;
}

.box_container {
  display: flex;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}

.boximage {
  position: absolute;
  padding: 20px;
  border: 1px solid #8D3C25;
  border-radius: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 238px;
  width: 100%; 
  top: 150px;
}

.box img {
  position: relative;
  height: 238px;
  margin-bottom: 10px;
  right: 50px;
  z-index: 1;
  border-radius: 10px;
}

.boxprice h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 28px;
  color: #361800;
  margin: 0.8rem 0;
  text-align: center;
}

.boxprice p {
  font-weight: normal;
  text-align: center;
}

.overview2 {
  margin-right: 20px;
}

.invest_section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;
  padding-left: 0;
  margin-top: 100px;
  margin-right: 30px;
  margin-bottom: 50px; 
}

.investimage {
  flex: 1 0 50%;
}

.invest1 {
  width: 100%;
}

.header_section {
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title3 {
  font-family: "Noto Serif Display";
  font-weight: 600;
  color: #933a1f;
  width: 60%;
  line-height: 1.50;
  margin-bottom: 0.5rem;
}

.details3 {
  font-size: 22px;
  line-height: 2.14rem;
  color: #361800;
  max-width: 600px;
  margin: 1rem 0 1rem 0;
}

.invest_list {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between; 
  margin-top: 20px;
  flex-direction: column;
}

.invest_item {
  display: flex;
  flex: 0 0 33%;
  margin-bottom: 20px;
  align-items: center;
  gap: 20px;
}

.invest_icon {
  width: 70px;
  height: 70px;
  margin-bottom: 10px; 
}

.invest_text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #361800;
  margin: 0.8rem 0;
  text-align: left;
  max-width: 562px;
}

.invest_item:nth-child(2) {
  border-top: 1px solid #8D3C25; 
  border-bottom: 1px solid #8D3C25;
  padding: 20px 0 20px 0;
  width: 561px;
}

.theme_section {
  padding-top: 3.5rem;
  padding-bottom: 9rem;
}

.theme_section .header_section {
  width: 50%;
  margin: 0 auto 4.35rem;
  text-align: center;
}

.theme_section .header_section .title {
  color: #8D3C25;
}

.theme_section .header_section .details {
  color: #06060b;
}

.card_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card_row {
  width: 420px;
  background-color: #F5EEE6;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 650px;
}

.card_row:nth-child(2) {
  background-color: #361800;
}

.card_row:nth-child(2) .card_title {
  color: #fff;
}

.card_row:nth-child(2) .vertical_line {
  background-color: #E99B24;
}

.card_title {
  font-weight: 600;
  color: #361800;
  margin: 3rem 0 0;
  text-align: center;
  max-width: 220px;
}

.card_icon {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.vertical_line {
  height: 350px;
  width: 2px; 
  background-color: #8D3C25;
  margin: 20px;
}

.text_container3 {
  color: #fff;
  margin-top: 1rem;
}

.text_container3 .title3 {
  font-family: "Noto Serif Display";
  font-size: 3rem;
  font-weight: 600;
  color: #933a1f;
  width: 85%;
  line-height: 1.65;
  margin-bottom: 0.5rem;
}

.text_container3 .title3::after {
  content: "";
  display: block;
  position: absolute;
  width: 656px;
  height: 2px;
  background-color: #933a1f;
  left: calc(100% - 590px);
  bottom: 0;
  margin-top: 0px;
  z-index: 1;
}

.strategyContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 3rem;
  gap: 185px;
}

.strategyimageContainer {
  margin-right: 20px;
  max-width: 600px;
}

.strategyContainer2 {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

.strategyList {
  padding: 0;
  margin: 0;
}

.strategyItem {
  font-size: 20px;
  font-weight: 400;
  color: #361800;
  margin-bottom: 10px;
}

.strategyItem:nth-child(3) { 
  border-bottom: 1px solid #8D3C25;
  padding-bottom: 40px;
  width: 500px;
}

.strategyContainer3{
  position: relative;
  z-index: 2;
  left: 330px;
  bottom: 150px;
}

.strategyimg2{
  max-width: 1000px;
}

.text_container4 {
  color: #fff;
  margin-top: 1rem;
}

.text_container4 .title4 {
  font-family: "Noto Serif Display";
  font-size: 3rem;
  font-weight: 600;
  color: #933a1f;
  width: 85%;
  line-height: 1.65;
  margin-bottom: 0.5rem;
}

.line_container {
  display: flex;
}

.line {
  width: 500px; 
  height: 1px;
  background-color: #8D3C25; 
  margin-right: 20px; 
}

.additional_text {
  margin-left: 20px; 
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  color: #8D3C25;
  text-align: left;
  max-width: 532px;
}

.icon_text_container {
  max-width: 900px;
  margin: 66px auto;
}

.icon_text_item {
  display: flex;
}

.icon_list {
  border-left: 1px solid #E99B24; 
  border-right: 1px solid #E99B24;
  padding: 0 20px;
}

.icon {
  width: 20px;
  height: 20px; 
  margin-right: 10px;
}

.icon_text {
  font-size: 20px;
  font-weight: 400;
  color: #361800;
  text-align: left;
}

.mallimg{
  margin-bottom: 5rem;
}

.text_container5 {
  color: #fff;
  margin-top: 1rem;
}

.text_container5 .title5 {
  font-family: "Noto Serif Display";
  font-size: 3rem;
  font-weight: 600;
  color: #933a1f;
  width: 85%;
  line-height: 1.65;
  margin-bottom: 0.5rem;
}

.text_container5 .title5::after {
  content: "";
  display: block;
  position: absolute;
  width: 656px;
  height: 2px;
  background-color: #933a1f;
  left: calc(100% - 590px);
  bottom: 0;
  
  margin-top: 0px;
  z-index: 1;
}

.dealContainer1 {
  display: flex;
  margin: 8rem 2rem 2rem 2rem;
  gap: 10px;
}

.dealcard {
  width: 300px;
  height: 65px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 35px 20px -2px rgba(0, 0, 0, 0.12);
  text-align: left;
}

.dealcardTitle {
  font-size: 18px;
  font-weight: 600;
  color: #8D3C25;
  text-align: left;
  margin-bottom: 0;
}

.dealcardText {
  font-size: 14px;
  font-weight: 500;
  color: #361800; 
}

.dealContainer2 {
  display: flex;
  margin-left: 2rem;
  gap: 50px;
}

.leftSection {
  margin-bottom: 2rem;
}

.dealcard2 {
  border: 1px solid #8D3C25;
  border-radius: 10px;
  padding: 10px 10px 1px;
  margin-bottom: 10px;
}

.rightSection{
  max-width: 656px;
  margin: 2rem 0;
}

.image {
  width: 640px;
  height: auto;
}

.allimagesContainer {
  width: 100%;
  overflow: hidden;
  margin-top: 3rem;
}

.imageSlider {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  gap: 10px;
  position: relative;
}

.sliderimage {
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: 400px;
  height: auto;
}

.previmage,
.nextimage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.previmage {
  left: 30px;
  width: 80px;
}

.nextimage {
  right: 30px;
  width: 80px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .hero_section {
    height: auto; 
  }

  .hero_text_section {
    flex-direction: column;
    align-items: center;
  }

  .text_container {
    margin: 5rem 0 2rem; 
  }

  .text_container .title {
    font-size: 2rem; 
    line-height: 1.5;
    width: 100%;
    text-align: left;
  }

  .text_container .title::after {
    width: 180px;
    left: calc(100% - 170px);
  }

  .content_wrapper {
    flex-direction: column; 
  }

  .text_container2 .title2 {
    font-size: 2rem;
    line-height: 1.5;
    width: 70%;
    text-align: left;
  }

  .text_container2 .details2 {
    font-size: 18px; 
    line-height: 1.5;
    max-width: 80%;
    margin: 1rem 0;
    text-align: left;
  }

  .text_container2 .title2::after {
    width: 180px;
    left: calc(100% - 170px);
  }

  .firstImage{
    width: 100%;
    z-index: 0; 
    left: 150px;
    top: 80px;

  }
  .secondImage {
    z-index: 5;
    left: 0;
    max-width: 250px;
  }

  .firstImage img {
    width: 300px;
    object-fit: cover;
  }

  .secondImage img {
    width: 100%;
    height: auto;

  }

  .left_content .titlea {
    font-size: 14px;
    line-height: 0.5;
    font-weight: 600;
  }

  .right_content {
    margin-top: 0;
    width: 100%;
  }

  .right_content .details {
    font-size: 18px; 
    line-height: 1.5;
  }

  .imagesection {
    padding: 0; 
  }

  .aboutContainer {
    flex-direction: column; 
    align-items: center;
  }

  .aboutImageContainer,
  .strategyContainer,
  .strategyContainer3 {
    margin: 20px 0; 
    min-height: 20rem;
  }

  .assetContainer{
    margin-bottom: 35rem;
  }

  .assetContainer,
  .assetContainer2,
  .assetContainerMain {
    display: block;
    text-align: center;
  }
  
  .asset_list {
    width: 100%;
    text-align: center; 
  }
  
  .asset_list:nth-child(1),
  .asset_list:nth-child(4) {
    margin: 30px auto 30px; 
    max-width: 150px; 
    display: block;
  }
  
  .asset_list:nth-child(2),
  .asset_list:nth-child(3) {
    display: inline-block;
    width: 45%;
    margin: 0 30px;
  }
  
  .asset_name,
  .asset_value {
    text-align: center;
  }
  
  .fastImage1 {
    margin: 0 2rem;
  }
  
  .fastImage2 {
    width: 100%;
    max-width: 24rem;
    height: 45%;
    margin: 2rem 0;
    position: absolute;
    left: 0;
  }

  .box_container {
    display: flex; 
    flex-wrap: wrap; 
  }

  .box {
    width: 100%; 
    margin: 0 1rem 7rem 0;
  }

  .boximage {
    display: block; 
    height: 130px;
    width: 80%;
    top: 200px;
    border-radius: 10px;
  }

  .boxprice {
    position: relative; 
    bottom: 0;          
    padding: 10px;     
    padding-top: 20px;
    text-align: center; 
  }

  .imgFluid1,
  .imgFluid2,
  .dealContainer1,
  .image,
  .allimagesContainer{
    width: 100%; 
  }

  .sliderimage {
    width: 260px;
  }

  .dealcard {
    box-shadow: 0 15px 15px 0px rgba(0, 0, 0, 0.12);
  }

  .dealcard,
  .dealcard2 {
    width: 100%; 
    max-width: 350px; 
    margin-bottom: 20px;
  }

  .dealcard{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 8px;    
  }

  .leftSection {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .rightSection {
    max-width: 100%; 
  }

  .invest_section {
    flex-direction: column; 
    align-items: center; 
    gap: 20px;
    padding: 20px; 
  }

  .invest_item{
    width: 100% !important;
  }

  .investimage,
  .header_section {
    width: 100%; 
  }

  .invest_text,
  .details3 {
    font-size: 18px;
  }

  .header_section .title,
  .header_section .details,
  .title3 {
    font-size: 2rem; 
    line-height: 1.5;
    width: 290px;
    text-align: left;
  }

  .theme_section,
  .why_section,
  .overview2 {
    padding: 20px; 
  }

  .theme_section .header_section,
  .why_section .header_section {
    width: 100%; 
    margin: 0 auto 2rem;
  }

  .card_container {
    flex-direction: column; 
    align-items: center; 
  }

  .card_row {
    width: 100%; 
    max-width: 300px; 
    margin-bottom: 20px; 
    height: auto;
  }

  .vertical_line {
    display: block; 
    height: 155px;
    width: 1px;

  }

  .text_container3 .title3{
    font-size: 2rem;
  }

  .text_container3 .title3::after,
  .text_container5 .title5::after {
    content: none; 
  }

  .strategyContainer,
  .strategyContainer2,
  .strategyContainer3 {
    width: 100%; 
  }

  .strategyimg2{
    width: 100%;
    height: 10rem;
  }

  .strategyContainer2 {
    padding-inline: 20px;
  }

  .strategyContainer {
    gap: 20px;
    flex-direction: column;
  }

  .strategyimageContainer {
    margin-right: 0; 
  }

  .strategyItem:nth-child(3) {
    border-bottom: none; 
    padding-bottom: 0; 
    width: 100%;
  }

  .strategyContainer3 {
    left: 0;
    bottom: 0;
  }

  .strategyItem {
    font-size: 18px; 
  }

  .text_container4 .title4 {
    font-size: 2rem; 
    line-height: 1.5;
    text-align: left; 
  }

  .line_container {
    flex-direction: column; 
    align-items: center; 
    margin-bottom: 20px;
  }

  .line {
    width: 100%; 
    margin-right: 0;
  }

  .additional_text {
    font-size: 18px;
    line-height: 1.5;
    margin-left: 0;
    max-width: 100%;
    padding-top: 46px;
    font-weight: 600;
  }

  .icon_text_container,
  .icon_list,
  .icon_text_item {
    width: 100%;
  }

  .icon_text_container {
    max-width: 470px; 
    width: 100%;
    margin: 35px auto;
  }

  .icon_list {
    border: none;
    border-top: 1px solid #E99B24; 
    border-bottom: 1px solid #E99B24;
    padding: 0 20px;
  }

  .icon_text_container .icon_text_item {
    width: 100%; 
    align-items: start;
    justify-content: center;
    margin: 10px 0;
  }

  .icon_text_container .icon_text_item .icon {
    margin-top: 9px;
  }
  .icon_text_container .icon_text_item .icon_text {
    color: #361800;
    font-size: 18px;
    width: 21rem;
  }

  .overlapPicture {
    width: 100%;
    height: 100%;
  }

  .text_container5 .title5 {
    font-size: 2rem;
    line-height: 1.3;
    color: #933a1f;
    width: 100%;
    padding-inline: 2rem;
    margin-bottom: 0.5rem;
  }

  .dealContainer1 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 4rem 0 1rem 0;
  }

  .dealContainer2 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
  
}