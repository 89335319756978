.nav_container {
  padding: 1.5rem 0;
  background: linear-gradient(180deg, #06060b 0%, rgba(6, 6, 11, 0) 100%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 5;
}

.hamburger_container {
  display: none;
}

.nav_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu_container {
  display: flex;
  justify-content: center;
}

.menu_group {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 0 3.5rem;
  padding: 0;
  margin: 0;
}

.menu_group li,
.menu_group .active {
  font-family: "Figtree";
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.menu_group li a {
  color: #b2b2b3;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
}

.menu_group li:last-child,
.follow_section {
  display: none;
}

.menu_group li a:hover,
.menu_group .active {
  color: #ffffff;
  font-weight: 700;
}

.menu_group li:hover ::after,
.menu_group .active::after {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  margin-left: -2px;
  z-index: 1;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 991.9px) {
  .nav_container {
    padding: 1.2rem 0;
  }

  .hamburger_container {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    position: relative;
    z-index: 40;
  }

  .hamburger_container .text {
    text-transform: uppercase;
    margin-right: 0.4rem;
    color: #ffffff;
  }

  .mobile_menu {
    position: fixed;
    top: -1500px;
    right: 0;
    left: -100%;
    height: 100vh;
    z-index: 35;
    background: url("../../../assets/images/bg/splash-image.png");
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.7s ease-in-out;
    padding: 2.5rem;
  }

  .mobile_menu_show {
    top: 0;
    left: 0;
  }

  /* url("../../../assets/images/nav-pattern.png"), */

  .mobile_menu::after {
    content: "";
    background: rgba(6, 6, 11, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
  }

  .menu_group {
    margin-top: 4.5rem;
    position: relative;
    z-index: 32;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem 0;
  }

  .menu_group li {
    font-family: "Noto Serif Display";
    line-height: 36px;
  }

  .menu_group li:last-child {
    display: inline-block;
  }

  .nav_content .btn_container {
    display: none !important;
  }

  .follow_section {
    display: block;
    position: absolute;
    left: 2.5rem;
    bottom: 3rem;
    z-index: 40;
  }

  .follow_title {
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.875rem;
    text-transform: uppercase;
  }

  .follow_container {
    display: flex;
    gap: 0 0.7rem;
  }

  .icon_holder {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
  }
}
