.hero_section {
  width: 100%;
  height: 680px;
  position: relative;
  background: url("../../assets/images/view-property-hero-section.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero_section::after {
  content: "";
  background: rgba(6, 6, 11, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
}

.basic_detail {
  background: #933a1f;
  border-radius: 0px 24px;
  padding: 1.5rem;
  position: relative;
  z-index: 1;
  top: -50px;
  height: 100px;
}

.detail_list_group {
  padding: 0;
  list-style: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
}

.detail_list .name {
  font-family: "Noto Serif Display";
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  margin-bottom: 0.3rem;
}

.detail_list .name::after {
  content: "";
  background-color: #ffffff;
  height: 1px;
  /* width: 100%; */
  position: absolute;
  right: 0;
  left: 0;
  bottom: -0.25rem;
}

.detail_list .value {
  font-size: 1.125rem;
  font-weight: 600;
}

.property_summary {
  position: relative;
  z-index: 1;
  padding: 3rem 0 9rem;
}

.room_list {
  margin-bottom: 2.5rem;
}

.room_list .name {
  color: #38383c;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}

.room_list .value {
  font-family: "Noto Serif Display";
  color: #ea9c22;
  font-weight: 700;
  font-size: 1.5rem;
}

.property_summary .text_section {
  font-size: 1rem;
}

.property_summary .text_section .title {
  font-weight: 500;
  color: #58585c;
  position: relative;
  margin-bottom: 1.25rem;
}

.property_summary .text_section .title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.3rem;
  height: 1px;
  background-color: #b2b2b3;
}

.property_summary .text_section .details {
  font-weight: 300;
  color: #06060b;
  margin-bottom: 0;
}

.photo_gallery_section {
  position: relative;
  z-index: 1;
}

.photo_title {
  top: -8rem;
  left: -2rem;
  color: #e6e6e7;
}

.gallery_container {
  margin-bottom: 4rem;
  position: relative;
  min-height: 400px;
}

.gallery_container .title {
  color: #58585c;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  position: relative;
}

.gallery_container .title::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #b2b2b3;
}

.other_properties_section {
  padding: 4rem 0;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 991.9px) {
  .hero_section {
    height: 570px;
  }

  .basic_detail {
    position: absolute;
    border-radius: 0;
    right: 0;
    left: 0;
    padding: 0;
    height: 85px;
  }

  .detail_list_group {
    gap: 0 2rem;
    flex-wrap: nowrap;
    padding: 1.2rem 0.8rem;
    overflow-x: auto;
    justify-content: unset;
  }

  .detail_list .name {
    font-size: 0.675rem;
    margin-bottom: 0.3rem;
  }

  .detail_list .value {
    font-size: 0.875rem;
  }

  .property_summary {
    padding: 3rem 0 8rem;
  }

  .room_list {
    margin-bottom: 2.5rem;
  }

  .room_list .name {
    font-size: 0.875rem;
    margin-bottom: 0.35rem;
  }

  .room_list .value {
    font-size: 1.125rem;
  }

  .property_summary .text_section {
    font-size: 0.875rem;
  }

  .property_summary .text_section .title {
    margin-bottom: 0.75rem;
  }

  .photo_title {
    left: -0.75rem;
    top: -2rem;
  }

  .gallery_container {
    margin-bottom: 3rem;
    min-height: 350px;
    overflow: hidden;
  }

  .gallery_container .title {
    color: #58585c;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    position: relative;
  }

  .gallery_container .title::after {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: #b2b2b3;
  }

  .other_properties_section {
    padding: 4rem 0;
    position: relative;
    z-index: 1;
  }
}

@media only screen and (max-width: 991.9px) and (min-width: 767.9px) {
  .detail_list_group {
    gap: 0 3rem;
    justify-content: center;
    max-width: 720px;
  }
}
