.card_section {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem;
  color: #38383c;
  border: 1px solid #fdf5e9;
  border-radius: 0px 24px;
  height: 100%;
}

.icon {
  width: 50px;
  display: inline-block;
  margin: 0 auto;
}

.title {
  font-size: 1rem;
  font-weight: 500;
  margin: 1.5rem 0 1rem;
  text-transform: uppercase;
}

.details {
  font-size: 1rem;
  font-weight: 300;
}

@media screen and (max-width: 767.9px) {
  .card_section {
    padding: 3rem 0.6rem;
  }
}
