.hero_section {
  width: 100%;
  height: 780px;
  position: relative;
  background: url("../../assets/images/properties-hero-section-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero_section::after {
  content: "";
  background: rgba(6, 6, 11, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
}

.hero_section .text_section {
  position: relative;
  z-index: 2;
  color: #ffffff;
  width: 65%;
  margin: 15rem auto;
  text-align: center;
}

.hero_section .text_section .title {
  font-size: 3.5rem;
  font-weight: 500;
  font-family: "Noto Serif Display";
  margin-bottom: 0.5rem;
}

.hero_section .text_section .details {
  font-size: 1.125rem;
  font-weight: 300;
  margin: 0 6rem;
}

.properties_section {
  padding: 4rem 0 4rem;
}

@media screen and (max-width: 991.9px) {
  .hero_section {
    height: 570px;
  }

  .hero_section .text_section {
    width: 100%;
    margin: 9rem auto;
  }

  .hero_section .text_section .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .hero_section .text_section .details {
    font-size: 0.875rem;
    margin: 0;
  }
}
