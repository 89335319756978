.hero_section {
  background: url("../../assets/images/contact-heor-section.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 620px;
  position: relative;
}

.hero_section::after {
  content: "";
  background: rgba(6, 6, 11, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  /* height: 100%; */
}

.hero_section .text_section {
  margin: 8rem auto 0;
  width: 65%;
  color: #ffffff;
  text-align: center;
  position: relative;
  z-index: 2;
}

.hero_section .text_section .title {
  font-family: "Noto Serif Display";
  font-weight: 500;
  font-size: 3.5rem;
}

.hero_section .text_section .details {
  font-size: 1.125rem;
  font-weight: 300;
  margin: 0 6rem;
}

.form_section {
  height: 550px;
}

.form_container {
  width: 50%;
  margin: 0 auto 0;
  background-color: #ffffff;
  padding: 4rem 3.6rem;
  position: relative;
  z-index: 1;
  top: -8rem;
}

@media screen and (max-width:991.9px){
  
.hero_section .text_section {
    margin: 8rem auto 0;
    width: 100%;
  }
  
  .hero_section .text_section .title {
    font-size: 1.5rem;
  }
  
  .hero_section .text_section .details {
    font-size: 0.875rem;
    margin: 0;
  }
  
  .form_section {
    height: 500px;
  }
  
  .form_container {
    width: 100%;
    padding: 2.5rem 1rem 5rem;
    top: -8rem;
  }
      
}
