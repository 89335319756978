/* 
 * Global styling are written here 
 * ********* Naming convention ************
 * **************** BEM *******************
 *
 * ********** Examples ***********
 * 
 *
 */

body {
  margin: 0;
  font-family: "Figtree", "Noto Serif Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

/* Typograph */
.section_header_title {
  font-size: 3rem;
  font-weight: 500;
  font-family: "Noto Serif Display";
  color: #05050a;
}

.section_header_details {
  font-size: 1.125rem;
  font-weight: 300;
  color: #06060b;
}

.main_title {
  color: #e6e6e7;
  font-weight: 500;
  font-family: "Noto Serif Display";
  font-size: 8rem;
  position: absolute;
}

@media screen and (max-width: 991.9px) {
  .section_header_title {
    font-size: 1.5rem;
  }

  .section_header_details {
    font-size: 0.875rem;
  }

  .main_title {
    font-size: 2.5rem;
    left: 0rem;
    top: -2rem;
  }
}

/* Button */
.btn {
  font-size: 0.875rem;
  font-weight: 700;
  border-radius: 0px 12px;
  /* border: 1px solid transparent; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  padding: 0.9rem 2rem;
  text-transform: uppercase;
}

.mh_primary_w_btn,
.mh_primary_b_btn {
  /* border-image: linear-gradient(to bottom, #ea9c22, #9a3d21, #58230d); */
  background-color: transparent !important;
  /* background-clip: content-box, border-box; */
  border: 1px solid #ea9c22;
  color: #ffffff;
  /* box-shadow: 2px 1000px 1px rgba(0, 0, 0, 0.1) inset; */
}

.mh_primary_b_btn {
  color: #06060b;
}

.mh_primary_w_btn:hover,
.mh_primary_b_btn:hover {
  background: linear-gradient(#eeb04e, #eeb04e) #eeb04e !important;
}

.mh_secondary_btn {
  background: #ea9c22;
  color: #ffffff;
  border: none;
}

.mh_secondary_btn:hover {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(to right, #ea9c22, #9a3d21, #58230d) border-box;
}

.mh_plain_btn {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.mh_plain_btn:hover {
  background: #ea9c22;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.mh_section_btn {
  color: #184d79;
  border: 1px solid #184d79;
  gap: 0 0.5rem;
}

.mh_section_btn:hover {
  color: #184d79;
  border: 1px solid #184d79;
  gap: 0 1.5rem;
}

/* background */

.mh_cream_n_masked {
  background-color: #fdf5e9;
}

.mh_white_n_masked {
  background-color: #ffffff;
}

.mh_cream_n_masked,
.mh_white_n_masked {
  position: relative;
}

.mh_cream_n_masked::after,
.mh_white_n_masked::after {
  content: "";
  background-image: url("./assets/images/bg/about-section-mask-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
  opacity: 0.85;
}

/* Accordion style */

.faq .accordion,
.faq .accordion-button,
.faq .accordion-item {
  background-color: transparent !important;
  border: none;
}

.faq button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  border-bottom: 1px solid #e6e6e7;
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/icons/minus.svg");
}

.faq .accordion-button::after {
  background-image: url("./assets/images/icons/add.svg");
}

/* Form */

.contact_form .form-control {
  height: 56px;
  border-radius: 0;
  font-family: "Figtree";
  color: #58585c;
}

.contact_form .form-control:focus {
  box-shadow: none;
  border: 1px solid #ea9c22;
}

.contact_form textarea {
  min-height: 120px;
}

/* Swiper */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  /* display: block;
  width: 100%; */
  /* height: 100%; */
  object-fit: cover;
}

.wwd_my_swiper {
  position: relative;
}

.wwd_my_swiper .nav_container {
  display: flex;
  gap: 0 2rem;
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
}

.nav_container .disable {
  pointer-events: none;
  opacity: 0.4;
}

.wwd_my_swiper .nav_container .prev_arrow,
.wwd_my_swiper .nav_container .next_arrow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 55px;
  border: 1.5px solid #ffffff;
  border-radius: 15px;
  cursor: pointer;
}

.mh_image_swiper_container {
  position: absolute;
  left: -7rem;
  right: -7rem;
  height: 100%;
  overflow: hidden;
}

.mh_image_swiper_container .swiper-slide img {
  height: 350px;
}

@media screen and (max-width: 991.9px) {
  .mh_image_swiper_container {
    left: -4rem;
    right: -4rem;
  }

  .mh_image_swiper_container .swiper-slide img {
    height: 290px;
  }

  .wwd_my_swiper .nav_container {
    position: unset;
    margin: 3rem auto 2rem;
  }

  .wwd_my_swiper .nav_container .prev_arrow,
  .wwd_my_swiper .nav_container .next_arrow {
    height: 35px;
    width: 35px;
    border-radius: 10px;
  }

  .wwd_my_swiper .nav_container .prev_arrow svg,
  .wwd_my_swiper .nav_container .next_arrow svg {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 767.9px) {
  .mh_image_swiper_container {
    left: -3rem;
    right: -3rem;
  }

  .mh_image_swiper_container .swiper-slide img {
    height: 300px;
    width: 100%;
  }
}
